
<app-filter-dropdown
	modalTitle="property_type"
	[filterSet]="typeBien.length > 0"
	[totalItems]="totalItems()"
	[contentScroll]="true"
	(filterSubmit)="handleSubmit()"
	(filterReset)="handleReset()"
	(filterClose)="handleClose()"
>
	<span filterButton>
		{{ (switchType === 'location' ? 'rent_verb' : 'buy_verb') | transloco | titlecase }}
		@if (typeBien[0]) {
			<span>
				{{ ', ' + (typeBien[0] | transloco) }}
			</span>
			@if (typeBien.length > 1) {
				<span class="filterTypePlus display-text-12px">
					+{{ typeBien.length - 1 }}
				</span>
			}
		}
	</span>

	<div class="switch">
		<label class="switch-item">
			<input type="radio" name="type" value="vente" [ngModel]="switchType" (ngModelChange)="handleSwitchType($event)" />
			<span>{{ 'buy_verb' | transloco | titlecase }}</span>
		</label>
		<label class="switch-item">
			<input type="radio" name="type" value="location" [ngModel]="switchType" (ngModelChange)="handleSwitchType($event)" />
			<span>{{ 'rent_verb' | transloco | titlecase }}</span>
		</label>
	</div>

	<div filterContent>

		<div class="modal-dropdown-text">
			{{ 'type' | transloco }}s
		</div>

		<div class="switch-bien">
			<label class="{{ switchBien === 'particulier' ? 'active-red-btn' : 'unactive-red-btn' }}" (click)="handleSwitchBien('particulier')">
				<span>{{ 'private' | transloco }}</span>
			</label>
			<label class="{{ switchBien === 'professionnel' ? 'active-red-btn' : 'unactive-red-btn' }}" (click)="handleSwitchBien('professionnel')">
				<span>{{ 'professional' | transloco }}</span>
			</label>
		</div>

		<div class="checkboxes">
			@if (switchBien === 'particulier') {
				<label class="checkbox" *ngFor="let t of TYPE_BIENS.particulier; let i = index">
					<input
						class="hidden"
						type="checkbox"
						[ngModel]="selected[0][i]"
						(ngModelChange)="handleChange(0, i, $event)"
					/>
					<span class="icon-check {{ selected[0][i] ? 'active-checkbox' : 'unactive-checkbox' }}"></span>
					<span class="text">{{ t.key | transloco }}</span>
				</label>
			} @else {
				<label class="checkbox" *ngFor="let t of TYPE_BIENS.professionnel; let i = index">
					<input
						class="hidden"
						type="checkbox"
						[ngModel]="selected[1][i]"
						(ngModelChange)="handleChange(1, i, $event)"
					/>
					<span class="icon-check {{ selected[1][i] ? 'active-checkbox' : 'unactive-checkbox' }}"></span>
					<span class="text">{{ t.key | transloco }}</span>
				</label>
			}
		</div>
	</div>

</app-filter-dropdown>
