import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, input, output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, Subscription, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { SearchAutocompleteStoreService } from '@/services//search-auto-complete.service';
import { StorageKey, StorageService } from '@/services/storage.service';
import { GeographyService } from '@/services/geography.service';
import { AgencesService } from '@/services/agences.service';
import { FilterService } from '@/services/filter.service';
import { GeolocService } from '@/services/geoloc.service';
import { MainRoutes, SEARCH_DEBOUNCE, SEARCH_MIN_LENGTH } from '@/constants';

import {
  Agence, AgenceGroupe, FilterTypeAnnonce, SearchLocation, SearchMode, SavedSearchType,
  SearchFilters, GeolocFeature, SearchGeoAutoCompletion, GeoAdresse,
  Coordinates
} from '@/models';

@Component({
  selector: 'app-search-bar-mobile',
  templateUrl: './search-bar-mobile.component.html',
  styleUrls: ['./search-bar-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SearchBarMobileComponent implements OnInit, OnDestroy {
  readonly foundGeoCoder = output<any>();

  readonly agenceGroupe = input<AgenceGroupe>();
  readonly agence = input<Agence>();

  private subscriptions = new Subscription();
  private searchMode: SearchMode = 'annonces';

  public searchType: FilterTypeAnnonce = 'vente';
  public inputAddress = new FormControl('');
  public closeAutocomplete = false;
  public autocompleteCities: SearchLocation[] = [];
  public autocompleteDepartments: SearchLocation[] = [];
  public autocompleteAdresses: SearchLocation[] = [];
  public submitDisabled = false;

  constructor(
    private searchAutocompleteStoreService: SearchAutocompleteStoreService,
    private geographyService: GeographyService,
    private agencesService: AgencesService,
    private storageService: StorageService,
    private geolocService: GeolocService,
    private filterService: FilterService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.searchAutocompleteStoreService.sharedautocompleteCities.subscribe((res) => {
        this.autocompleteCities = res;
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.searchAutocompleteStoreService.sharedautocompleteDepartments.subscribe((res) => {
        this.autocompleteDepartments = res;
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.inputAddress.valueChanges.pipe(
        filter((value) => (value?.length ?? 0) >= SEARCH_MIN_LENGTH),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((value) => (
          this.geographyService.getAutocompletion(value!, this.searchType === 'estimer' ? 'address' : 'where')
        ))
      ).subscribe((data) => {
        this.processReceivedLocations(data);
        this.closeAutocomplete = true;
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmitButton(): void {
    if ((this.searchType === 'estimer') && this.inputAddress.value) {
      this.geolocService.updateGeoCoder(this.inputAddress.value);

      const agenceGroupe = this.agenceGroupe();
      const agence = this.agence();
      const params: any = {};

      if (agenceGroupe) {
        params.queryParams = { agence_id: agenceGroupe.agences[0].id };
      } else if (agence) {
        params.queryParams = { agence_id: agence.id };
      }

      this.router.navigate([MainRoutes.Estimer], params);
    }
  }

  handleAutocompleteAddress(adresse: SearchLocation): void {
    this.setAddress(adresse.nom);
    this.setCoordinates(adresse.geoloc!);

    this.geolocService.searchAddressAutocomplete();
    this.geolocService.saveAddress(adresse);

    this.closeAutocomplete = false;
    this.submitDisabled = false;
  }

  setAddress(address: string): void {
    this.inputAddress.setValue(address, { emitEvent: false });
  }

  closeAutoComplete(): void {
    this.closeAutocomplete = false;
  }

  select(locationItem: SearchLocation): void {
    this.closeAutoComplete();
    this.setAddress('');

    this.filterService.updateFilters(this.searchMode, { searchLocations: [locationItem], type_annonce: this.searchType });
    this.filterService.buildRouteFromFilters({ mode: this.searchMode });
  }

  getLastSearch(type: SavedSearchType): SearchFilters | undefined {
    const lastSearch: any = this.storageService.getObject(StorageKey.LastSearch);
    return lastSearch ? lastSearch[type] : undefined;
  }

  lastSearchTextFromParams(type: SavedSearchType): string[] {
    const lastSearch = this.getLastSearch(type);
    const lastSearchText: string[] = [];

    if (lastSearch) {
      const locations = lastSearch.searchLocations?.map((location) => location.nom).join(',');

      if (locations) {
        lastSearchText.push(locations);
      }

      if (lastSearch.typeBien?.length) {
        lastSearchText.push(lastSearch.typeBien.join(','));
      }

      if (lastSearch.nb_pieces) {
        lastSearchText.push(lastSearch.nb_pieces + ' pièces');
      }

      if (lastSearch.surface_from || lastSearch.surface_to) {
        let surfaceText = lastSearch.surface_from ? lastSearch.surface_from : '';

        if (surfaceText.length && lastSearch.surface_to) {
          surfaceText = surfaceText + ' - ' + lastSearch.surface_to;
        }

        if (!surfaceText.length && lastSearch.surface_to) {
          surfaceText = lastSearch.surface_to;
        }

        if (surfaceText) {
          surfaceText = surfaceText + ' m2';
          lastSearchText.push(surfaceText);
        }
      }
    }

    return lastSearchText;
  }

  goToLastSearch(type: SavedSearchType): void {
    const lastSearch = this.getLastSearch(type);
    this.filterService.updateFilters(this.searchMode, lastSearch ?? {});
    this.filterService.buildRouteFromFilters({ mode: this.searchMode });
  }

  getGeoAddress(geoloc: GeolocFeature): GeoAdresse {
    return geoloc?.properties as GeoAdresse;
  }

  private processReceivedLocations(suggestions: SearchGeoAutoCompletion): void {
    const { villes, departements, adresses } = suggestions;

    this.autocompleteDepartments = departements ?? [];
    this.autocompleteCities = villes ?? [];
    this.autocompleteAdresses = adresses ?? [];
  }

  private setCoordinates({ lat, lng }: Coordinates<number>): void {
    this.subscriptions.add(
      this.agencesService.getNearestAgency(`${lng}%2C${lat}`, 1).subscribe(({ data }) => {
        this.geolocService.updateNearestAgency(data);
        this.cd.markForCheck();
      })
    );
  }
}
