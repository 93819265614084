import { Component, forwardRef, Inject, OnChanges, OnInit, signal, SimpleChanges, ViewEncapsulation, input, output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, Subscription, switchMap } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { GeographyService } from '@/services/geography.service';
import { GeolocService } from '@/services/geoloc.service';
import { ConfigService } from '@/services/config.service';
import { MapService } from '@/services/map.service';
import { FormLocationFilters, SearchLocation, SearchMode } from '@/models';

const LOCATION_INPUT_MIN_SIZE = 3;

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LocationFilterComponent),
    multi: true
  }],
  standalone: false
})
export class LocationFilterComponent implements OnChanges, OnInit, ControlValueAccessor {
  readonly mode = input.required<SearchMode>();
  readonly totalItems = input(0);

  readonly closed = output();
  readonly validate = output();
  readonly reinit = output<FormLocationFilters>();

  private subscriptions = new Subscription();

  public searchLocations: SearchLocation[] = [];
  public polygon?: string = '';
  public distance = 0;

  public showDropdown = signal(false);

  public locationInputValue = new FormControl('');
  public foundDepartments: SearchLocation[] = [];
  public foundCities: SearchLocation[] = [];
  public currentGeoloc?: SearchLocation;
  public searchOnMapMove!: boolean;
  public showRadiusFilter = true;
  public currentId = 0;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private geographyService: GeographyService,
    private configService: ConfigService,
    private geolocService: GeolocService,
    private mapService: MapService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.mapService.searchOnMapMove$.subscribe((searchOnMapMove) => {
        this.searchOnMapMove = searchOnMapMove;
      })
    );

    this.subscriptions.add(
      this.geolocService.currentGeolocVille$.subscribe((data) => {
        this.searchLocations = data ? [data] : [];
        this.currentGeoloc = data;
      })
    );

    // TODO-EL: à refaire
    if (this.configService.isBrowser) {
      // HANDLE KEY UP OR DOWN FOR AUTOCOMPLETION
      this.document.addEventListener('keydown', (event) => {
        this.handleUpDownKeyAutoCompletion(event);
        event.stopImmediatePropagation();
      });
    }

    // Autocomplete stuff
    this.subscriptions.add(
      this.locationInputValue.valueChanges.pipe(
        filter((value) => (value ?? '').length > LOCATION_INPUT_MIN_SIZE),
        switchMap((value) => this.geographyService.getAutoCompleteList(value!))
      ).subscribe(({ departements, villes }) => {
        this.showDropdown.set(!!(departements?.length || villes?.length));
        this.foundDepartments = departements?.slice(0, 5) ?? [];
        this.foundCities = villes?.slice(0, 5) ?? [];
        this.currentId = 0;

        const el = this.document.getElementById('autoComp-' + this.currentId);
        if (el) {
          el.classList.add('selectStyle');
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode']?.currentValue) {
      this.showRadiusFilter = (this.mode() === 'annonces');
    }
  }

  closeAutoComplete() {
    this.showDropdown.set(false);
    this.currentId = 0;
  }

  handleUpDownKeyAutoCompletion = (event: KeyboardEvent) => {
    // TODO-EL: NOOONN !!! UTILISER LES VIEWCHILDREN
    const allElems = this.document.querySelectorAll('*[id^="autoComp-"]');
    const totalIds = allElems.length;

    const currentElem = this.document.getElementById('autoComp-' + this.currentId);

    if (currentElem) {
      if (event.code === 'ArrowDown') {
        this.currentId += 1;
        if (this.currentId === totalIds) {
          this.document.getElementById('autoComp-' + `${this.currentId - 1}`)?.classList.remove('selectStyle');
          this.currentId = 0;
        }
        if (this.currentId > 0) {
          this.document.getElementById('autoComp-' + `${this.currentId - 1}`)?.classList.remove('selectStyle');
        }
        this.document.getElementById('autoComp-' + this.currentId)?.classList.add('selectStyle');
      } else if (event.code === 'ArrowUp') {
        this.currentId -= 1;
        if (this.currentId < 0) {
          this.currentId = totalIds - 1;
          currentElem.classList.add('selectStyle');
          this.document.getElementById('autoComp-0')?.classList.remove('selectStyle');
        }
        if (this.currentId !== totalIds - 1) {
          this.document.getElementById('autoComp-' + `${this.currentId + 1}`)?.classList.remove('selectStyle');
        }
        this.document.getElementById('autoComp-' + this.currentId)?.classList.add('selectStyle');
      }
      if (event.code === 'Enter') {
        this.document.getElementById('autoComp-' + this.currentId)?.click();
      }
    }
  };

  handleSelect(e: Event, item: SearchLocation): void {
    const index = this.searchLocations.findIndex((l) => l.nom === item.nom);
    if (index < 0) {
      this.searchLocations = [...this.searchLocations, item];
      this.update();

      this.locationInputValue.setValue('');
      this.showDropdown.set(false);
    }

    e.stopPropagation();
  }

  handleUnselect(e: Event, item: SearchLocation): void {
    this.searchLocations = this.searchLocations.filter((l) => l.nom !== item.nom);
    this.update();

    if (this.currentGeoloc) {
      this.geolocService.updateGeolocVille(undefined);
    }

    e.stopPropagation();
  }

  sliderChange = (value: number) => {
    this.distance = value;
    this.update();
  };

  handleAskForGeoloc(): void {
    this.subscriptions.add(
      this.geolocService.getSearchLocationFromPosition().subscribe({
        next: (searchLocation) => {
          if (searchLocation) {
            this.searchLocations = [searchLocation];
            this.update();
          }
        },
        error: (error) => {
          // TODO_BA: ToastService
          console.error('error', error);
        }
      })
    );
  }

  private update(): void {
    if (this.searchLocations.length > 0) {
      this.polygon = '';
    }

    this.onChange({
      searchLocations: this.searchLocations,
      distance: this.distance,
      polygon: this.polygon
    });

    this.onTouched();
  }

  handleReset(): void {
    this.geolocService.updateGeolocVille(undefined);
    this.reinit.emit({ searchLocations: [], distance: '', polygon: '' });
  }

  handleSubmit(): void {
    this.validate.emit();
  }

  handleClose(): void {
    this.closed.emit();
  }

  writeValue({ searchLocations, distance, polygon }: FormLocationFilters): void {
    this.searchLocations = searchLocations ?? [];
    this.distance = distance ? +distance : 0;
    this.polygon = polygon;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
