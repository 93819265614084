<div (clickOutside)="handleCancel()" class="filter-dropdown">
	@if (showModal) {
		<div class="filter-mobile-back">
			<div class="previous-button-btn" (click)="handleCancel()">
				<div class="icon-arrow-left"></div>
				<p>{{ modalTitle() ?? buttonTitle() | transloco }}</p>
			</div>
		</div>
	}

	<app-button-tag [active]="showModal || filterSet()" (click)="toggleModal()">
    @if (iconName(); as icon) {
		  <span class="filter-icons {{icon}}"></span>
    }
		@if (!filterSet()) {
			{{ buttonTitle() | transloco }}
		}
		<ng-content select="[filterButton]" />
	</app-button-tag>

	@if (showModal) {
		<div class="modal-dropdown">
			<span class="modal-dropdown-text">
				{{ (contentTitle() ?? buttonTitle()) | transloco }}
			</span>

			<ng-content />

			<div [ngClass]="{ 'scrollInModal': contentScroll() }">
				<ng-content select="[filterContent]" />
			</div>

			<div class="modal-dropdown-footer">
				<span (click)="handleReset()" class="cancel-btn">
					{{ 'reset' | transloco }}
				</span>
				<button (click)="handleSubmit()" class="search-btn-modal">
					{{ 'to_search' | transloco }} ({{ totalItems() }})
				</button>
			</div>
		</div>
	}

</div>