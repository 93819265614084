import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { NgModule, provideAppInitializer } from '@angular/core';

import { translocoPreLoad } from './transloco-preload';
import { translocoConfig } from './transloco-config';

@NgModule({
  exports: [
    TranslocoModule
  ],
  providers: [
    provideAppInitializer(translocoPreLoad),
    provideTransloco(translocoConfig)
  ]
})
export class TranslocoRootModule { }
