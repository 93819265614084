import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { Toast } from '../../models/toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ToastComponent {
  readonly toast = input.required<Toast>();
  readonly index = input(0);

  readonly remove = output<number>();

  handleClose(): void {
    this.remove.emit(this.toast().id!);
  }
}
