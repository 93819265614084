<app-modal-container>
	<div class="modal-register">
		<div class="w-full flex justify-between content-center">
			<div class="title">
				<p (click)="onBack()"><span class="icon-arrow-left"></span></p>
				<h3 class="display-h3-28px">{{ 'create_account' | transloco }}</h3>
			</div>
			<p (click)="onClose()"><span class="icon-close"></span></p>
		</div>

		@if (done) {
			<div>
				<div class="w-full flex justify-between items-center">
					@if (loading) {
						<h3 class="display-h3">
							{{ 'registration in progress' | transloco }}
						</h3>
					}
				</div>

				<p class="display-text-16px mb-20px">
					{{ 'allow to connect' | transloco }}
				</p>

				<app-button
					text="to login"
					size="large"
					type="button"
					(click)="onBack()"
				/>

				<input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
				<input type="hidden" name="action" value="validate_captcha" />
			</div>
		} @else {
			<form [formGroup]="registerForm" (submit)="onSubmit()">
				@if (messageError) {
					<div class="messageError">
						<p>{{ 'account already exists' | transloco }}</p>
					</div>
				}

				<app-form-input id="email" label="email" icon="icon-email" formControlName="email" [validationErrors]="submitted" />

				<app-form-input id="nom" label="name" icon="icon-user" formControlName="nom" [validationErrors]="submitted" />

				<app-form-input id="prenom" label="firstname" icon="icon-user" formControlName="prenom" [validationErrors]="submitted" />

				<app-form-input
          type="password"
          id="password"
          label="password"
          formControlName="password"
          [validationErrors]="submitted"
          [passwordTips]="true"
        />

				<app-form-input
          type="password"
          id="password_confirmation"
          label="confirm password"
          formControlName="password_confirmation"
          [validationErrors]="submitted"
        />

				<div class="rgpd">
					<app-form-checkbox id="contact_agreement" label="authorization" formControlName="contact_agreement" />
					<app-form-checkbox id="partner_agreement" label="authorization_data" formControlName="partner_agreement" />
				</div>

        <app-button-loading
          [disabled]="registerForm.invalid && submitted"
          [loading]="loading"
          text="validate"
        />
			</form>
		}
	</div>
</app-modal-container>
