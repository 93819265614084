import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CustomRouteReuseStategy implements RouteReuseStrategy {

  shouldDetach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(_route: ActivatedRouteSnapshot, _detachedTree: DetachedRouteHandle): void {
  }

  shouldAttach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  /**
   * Checks if the route should be reused in order to keep already created component.
   * @param future The next activated route
   * @param curr The current activated route
   * @returns true if we can reuse the same component, else false.
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    while (curr.firstChild) {
      if (curr.routeConfig?.data?.['shouldReuse']) {
        break;
      }
      curr = curr.firstChild;
    }

    while (future.firstChild) {
      if (future.routeConfig?.data?.['shouldReuse']) {
        break;
      }
      future = future.firstChild;
    }

    return (future.component === curr.component) && !!future.routeConfig?.data?.['shouldReuse'];
  }
}
