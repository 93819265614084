import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { FooterItem } from '@/models';

@Component({
  selector: 'app-footer-column',
  templateUrl: './footer-column.component.html',
  styleUrl: './footer-column.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FooterColumnComponent {
  readonly type = input<'nav' | 'faq'>('nav');
  readonly colTitle = input<string>();
  readonly items = input<FooterItem[]>();
}
