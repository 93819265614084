import { ChangeDetectionStrategy, Component, ViewEncapsulation, input, output } from '@angular/core';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrl: './filter-dropdown.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FilterDropdownComponent {
  readonly buttonTitle = input<string>();
  readonly contentTitle = input<string>();
  readonly modalTitle = input<string>();
  readonly iconName = input<string>();
  readonly filterSet = input(false);
  readonly totalItems = input(0);
  readonly contentHeight = input('');
  readonly contentScroll = input(false);

  readonly filterClosed = output();
  readonly filterSubmit = output();
  readonly filterReset = output();

  public showModal = false;

  toggleModal(): void {
    this.showModal = !this.showModal;
    if (!this.showModal) {
      this.filterClosed.emit();
    }
  }

  handleCancel(): void {
    if (this.showModal) {
      this.showModal = false;
      this.filterClosed.emit();
    }
  }

  handleReset(): void {
    this.showModal = false;
    this.filterReset.emit();
  }

  handleSubmit(): void {
    this.showModal = false;
    this.filterSubmit.emit();
  }
}
