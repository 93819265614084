import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Agence, AgenceGroupe, ConnectedUser, CurrentRoute, HeaderModalType, HeaderType, MainHeaderState, SearchMode } from '@/models';
import { RouteObserverService } from '@/services/route-observer.service';
import { AgencesService } from '@/services/agences.service';
import { HeaderService } from '@/services/header.service';
import { ConfigService } from '@/services/config.service';
import { AuthService } from '@/services/auth.service';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { ngSkipHydration: 'true' },
  standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;

  public queryParams: Record<string, number> = {};
  public disableAlertButton = false;
  public agence?: Agence;
  public agenceGroupe?: AgenceGroupe;
  public modal: HeaderModalType = 'none';
  public currentRoute?: CurrentRoute;
  public connectedUser?: ConnectedUser;
  public showHeaderType: HeaderType = 'main';
  public currentItemsCount = 0;
  public searchMode?: SearchMode;
  public headerShadow = true;

  constructor(
    private routeObserver: RouteObserverService,
    private agencesService: AgencesService,
    private headerService: HeaderService,
    private configService: ConfigService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private router: Router
  ) {
    this.connectedUser = this.authService.connectedUser;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.connectedUser$.subscribe((user) => {
        this.connectedUser = user;
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.routeObserver.currentRoute$.subscribe((route) => {
        this.setHeaderSettings(route);
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.headerService.headerState$.subscribe((state) => {
        this.processNewHeaderState(state);
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Open or close a popup modal dialog
   * @param type 'none' to close the opened modal, else the modal type
   */
  openModal(type: HeaderModalType): void {
    if (!this.configService.isBrowser || ((type === 'alert') && this.disableAlertButton)) {
      return;
    }

    if (type === 'none') {
      switch (this.modal) {
        case 'login':
          this.headerService.showLoginModal(false);
          break;
        case 'connect':
          this.headerService.showNeedConnectModal(false);
          break;
        case 'reset-password':
          this.router.navigate(['/']);
          break;
      }
    }

    this.modal = type;
  }

  /**
   * Route has changed, adapt header type to the requested mode (route data) and load
   * agence or agence group if needed.
   * @param route The new current route
   */
  private setHeaderSettings(route: CurrentRoute): void {
    const { type, queryParams, data } = route;

    this.headerShadow = !data.hideHeaderShadow;
    this.searchMode = data.searchMode;
    this.currentRoute = route;

    if (data.openModal) {
      this.modal = data.openModal;
    }

    // Check if we have an agence in the request, either path or query params
    const agenceId = Number(queryParams['agence_id']) || undefined;
    const groupeId = Number(queryParams['groupe_id']) || undefined;
    const isGroupe = type === MainRoutes.Groupes;
    const slugId = route.slugId;

    if (agenceId || groupeId || slugId) {
      this.showHeaderType = 'agence';
    } else {
      this.showHeaderType = data.headerType || 'main';
    }

    if (this.agenceHasChanged(isGroupe, agenceId || groupeId || slugId)) {
      this.clearAgence();
    }

    this.headerService.showSearchBar(false);
    this.headerService.setHeaderType(this.showHeaderType);

    if ((this.showHeaderType === 'agence') || data.getAgence) {
      if (isGroupe) {
        if (slugId || groupeId) {
          const id = (slugId ?? groupeId)!;

          this.queryParams = { groupe_id: id };

          if (!this.agenceGroupe || this.agenceGroupe.id !== id) {
            this.agenceGroupesById(id);
          }
        }
      } else if (slugId || agenceId) {
        const id = (slugId ?? agenceId ?? groupeId)!;

        if (slugId) {
          this.queryParams = { agence_id: slugId };
        } else if (agenceId) {
          this.queryParams = { agence_id: agenceId };
        } else if (groupeId) {
          this.queryParams = { groupe_id: groupeId };
        }

        if (!this.agence || this.agence.id !== id) {
          this.agenceById(id);
        }
      }
    } else {
      this.clearAgence();
    }

    // Reset header visibility when page or params changes
    this.headerService.setLowIndex(false);
  }

  /**
   * Adjust UI when header state has changed
   * @param state The new header state read from dedicated service.
   */
  private processNewHeaderState(state: MainHeaderState): void {
    if (state.disableAlertButton !== this.disableAlertButton) {
      this.disableAlertButton = state.disableAlertButton;
    }

    if (state.modal !== this.modal) {
      this.modal = state.modal;
    }

    this.currentItemsCount = state.currentItemsCount ?? 0;
    this.setHeaderVisibility(state.lowIndex);
  }

  /**
   * Get agence details
   * @param id The agency id
   */
  private agenceById(id: number): void {
    this.subscriptions.add(
      this.agencesService.getAgenceById(id, true).subscribe(({ data }) => {
        this.agence = data;
        this.cd.markForCheck();
      })
    );
  }

  /**
   * Get agence groupe details
   * @param id The agency group id
   */
  private agenceGroupesById(id: number): void {
    this.agencesService.getAgenceGroupesById(id, true).subscribe(({ data }) => {
      this.agenceGroupe = data;
      this.cd.markForCheck();
    });
  }

  /**
   * Change main header visibility precedence according to the lowIndex flag
   * @param behind true if header must be hidden when some other component cover it, else false
   */
  private setHeaderVisibility(behind = false): void {
    if (behind) {
      this.el.nativeElement.style.position = 'absolute';
      this.el.nativeElement.style.zIndex = '0';
    } else {
      this.el.nativeElement.style.position = 'sticky';
      this.el.nativeElement.style.zIndex = '100';
    }
  }

  /**
   * See if current agence has changed
   * @param newType The new agence type
   * @param id The new agence identifier
   * @return true if new identifier if different from old one, else false
   */
  private agenceHasChanged(isGroupe: boolean, id?: number): boolean {
    if ((isGroupe && !this.agenceGroupe) || (!isGroupe && !this.agence)) {
      return true;
    }

    const agence = this.agence ?? this.agenceGroupe;
    return agence ? (!id || agence.id !== id) : false;
  }

  /**
   * Forget last agence
   */
  private clearAgence(): void {
    this.agencesService.clearAgence();
    this.agenceGroupe = undefined;
    this.agence = undefined;
  }
}
