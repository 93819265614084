import { Component, output } from '@angular/core';

@Component({
  selector: 'app-modal-alert-need-to-connect',
  templateUrl: './modal-alert-need-to-connect.component.html',
  styleUrls: ['./modal-alert-need-to-connect.component.scss'],
  standalone: false
})
export class ModalAlertNeedToConnectComponent {
  readonly closeModal = output();
  readonly openLogin = output();

  onClose() {
    this.closeModal.emit();
  }

  onConnect() {
    this.openLogin.emit();
  }
}
