import { Component, output } from '@angular/core';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss'],
  standalone: false
})
export class ValidateComponent {
  readonly closeModal = output();
  readonly openLogin = output();

  onModalLogin() {
    this.openLogin.emit();
  }

  onClose() {
    this.closeModal.emit();
  }
}
