<app-filter-dropdown
  buttonTitle="area"
  contentTitle="living_area"
  iconName="icon-expand"
  [filterSet]="!!(surfaceFrom || surfaceTo || terrainFrom || terrainTo)"
  [totalItems]="totalItems()"
  (filterSubmit)="handleSubmit()"
  (filterReset)="handleReset()"
  (filterClose)="handleClose()"
>
  <span filterButton>
    @if (surfaceFrom || surfaceTo) {
      {{ surfaceFrom || '' }} - {{ surfaceTo ?? '' }} m<sup>2</sup>
    }
    @if (terrainFrom || terrainTo) {
      {{ (surfaceFrom || surfaceTo) ? ' / ' : ''}}
      {{ terrainFrom || '' }} - {{ terrainTo ?? '' }} m<sup>2</sup>
    }
  </span>

  <div filterContent>
    <div class="form-inputs">
      <app-form-input
        [ngModel]="surfaceFrom"
        (ngModelChange)="handleChange('surfaceFrom', $event)"
        [ngClass]="{ transformed: !!surfaceFrom }"
        type="number"
        suffix="m²"
        label="min"
        id="sfrom"
      />
      <app-form-input
        [ngModel]="surfaceTo"
        (ngModelChange)="handleChange('surfaceTo', $event)"
        [ngClass]="{ transformed: !!surfaceTo }"
        type="number"
        suffix="m²"
        label="max"
        id="sto"
      />
    </div>

    <span class="modal-dropdown-text">
      {{ 'habitable_area' | transloco }}
    </span>

    <div class="form-inputs">
      <app-form-input
        [ngModel]="terrainFrom"
        (ngModelChange)="handleChange('terrainFrom', $event)"
        [ngClass]="{ transformed: !!terrainFrom }"
        type="number"
        suffix="m²"
        label="min"
        id="tfrom"
      />
      <app-form-input
        [ngModel]="terrainTo"
        (ngModelChange)="handleChange('terrainTo', $event)"
        [ngClass]="{ transformed: !!terrainTo }"
        type="number"
        suffix="m²"
        label="max"
        id="tto"
      />
    </div>
  </div>

</app-filter-dropdown>