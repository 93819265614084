<section class="footer">
	<div class="footer-container">
		<nav class="footer-nav">

			<app-footer-column colTitle="speaking_of" [items]="speakingItems" />
			<app-footer-column colTitle="purchase" [items]="purchaseItems" />
			<app-footer-column colTitle="rental" [items]="rentalItems" />

			@if (labelItems.length) {
				<app-footer-column colTitle="label" [items]="labelItems" />
			}

			<app-footer-column colTitle="find_us">
				<li><app-network [footer]="true" /></li>
			</app-footer-column>

		</nav>
	</div>

	<app-footer-column type="faq" [items]="faqItems" />
</section>

@if (showLegalNotice) {
	<app-modal-legal-notice
		(closeModalLegalNotice)="showLegalNotice = false"
		[pageType]="pageType"
		[agence]="agence"
	/>
}
