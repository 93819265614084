<div [ngClass]="type()">
  @if (colTitle(); as title) {
    <div class="title display-h5">{{ title | transloco }}</div>
  }
  <ul>
    @if (items(); as list) {
      @for (item of list; track i; let i = $index) {
        <li>
          @if (item.img) {
            <img [src]="item.img" [alt]="item.key" />
          } @else if (item.label) {
            <span>{{ item.label }}{{ item.title ?? (item.key | transloco) }}</span>
          } @else if (item.href) {
            <a [href]="item.href" target="_blank">{{ item.title ?? (item.key | transloco) }}</a>
          } @else if (item.click) {
            <span class="action" (click)="item.click()">{{ item.title ?? (item.key | transloco) }}</span>
          } @else {
            <a [routerLink]="item.path">{{ item.title ?? (item.key | transloco) }}</a>
          }
        </li>
      }
    } @else {
      <ng-content />
    }
  </ul>
</div>
