@if (currentRoute) {
  @if (showHeaderType === 'main') {
    <app-header-main
      [currentRoute]="currentRoute"
      [connectedUser]="connectedUser"
      [searchMode]="searchMode"
      [filtersTemplate]="filters"
      [headerShadow]="headerShadow"
      (openModal)="openModal($event)"
    />
  } @else {
    <app-header-agence
      [currentRoute]="currentRoute"
      [connectedUser]="connectedUser"
      [searchMode]="searchMode"
      [filtersTemplate]="filters"
      [agence]="agence"
      [agenceGroupe]="agenceGroupe"
      [queryParams]="queryParams"
      [headerShadow]="headerShadow"
      (openModal)="openModal($event)"
    />
  }
}

<!-- HEADER FILTERS -->
<ng-template #filters>
  @if (searchMode) {
    <div class="filter-search">
      <app-filters [searchMode]="searchMode" [currentItemsCount]="currentItemsCount" />

      @if (searchMode === 'annonces') {
        <app-button
          (click)="openModal('alert')"
          [disabled]="disableAlertButton"
          icon="icon-notification"
          class="active-alert"
          text="active_alert"
        >
          <div class="tooltip-alert-button">
            <p class="display-text-14px">{{ 'active_alert_message' | transloco }}</p>
          </div>
        </app-button>
      }
    </div>
  }
</ng-template>

@if (modal !== 'none') {
  @switch (modal) {
    @case ('login') {
      <app-login
        (openRegister)="openModal('register')"
        (openForgot)="openModal('forgot')"
        (closeModal)="openModal('none')"
      />
    }
    @case ('forgot') {
      <app-forgot
        (back)="openModal('login')"
        (closeModal)="openModal('none')"
      />
    }
    @case ('register') {
      <app-register
        (back)="openModal('login')"
        (validate)="openModal('validate')"
        (closeModal)="openModal('none')"
      />
    }
    @case ('validate') {
      <app-validate
        (openLogin)="openModal('login')"
        (closeModal)="openModal('none')"
      />
    }
    @case ('alert') {
      <app-modal-active-alert
        [connected]="!!connectedUser"
        (closeActiveAlert)="openModal('none')"
        (openLogin)="openModal('login')"
      />
    }
    @case ('connect') {
      <app-modal-alert-need-to-connect
        (openLogin)="openModal('login')"
        (closeModal)="openModal('none')"
      />
    }
    @case ('reset-password') {
      <app-reset
        (closeModal)="openModal('none')"
        (openLogin)="openModal('login')"
      />
    }
  }
}
