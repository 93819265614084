import { ChangeDetectionStrategy, Component, ElementRef, input, viewChild } from '@angular/core';

import { AuthService } from '@/services/auth.service';
import { BurgerMenuItem, ConnectedUser } from '@/models';

import { HeaderService } from '@/services/header.service';
import { ConfigService } from '@/services/config.service';
import { pageAnimation } from '@/utilities/animations';
import { FavorisRoute } from '@/constants';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrl: './burger-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [pageAnimation],
  standalone: false
})
export class BurgerMenuComponent {
  readonly transition = viewChild.required<ElementRef>('transition');
  readonly connectedUser = input<ConnectedUser>();
  readonly items = input<BurgerMenuItem[][]>([]);

  public FavorisRoute = FavorisRoute;
  public isOpen = false;

  constructor(
    private configService: ConfigService,
    private headerService: HeaderService,
    private authService: AuthService
  ) {}

  togglePanel(): void {
    this.isOpen = !this.isOpen;
    this.updatePanel();
  }

  handleLogin(): void {
    this.isOpen = false;
    this.updatePanel();
    this.headerService.showLoginModal();
  }

  handleLogout(): void {
    this.isOpen = false;
    this.updatePanel();

    this.authService.logout().subscribe({
      error: (error) => {
        console.error('Logout error', error);
      }
    });
  }

  private updatePanel(): void {
    if (this.configService.isBrowser) {
      if (this.isOpen) {
        this.transition().nativeElement.classList.add('active');
      } else {
        this.transition().nativeElement.classList.remove('active');
      }
    }
  }
}
