import { ApplicationRef, ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2, signal } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TranslocoService } from '@jsverse/transloco';
import { DOCUMENT } from '@angular/common';
import { first } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { ConfigService } from '@/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent implements OnInit {
  public hideFooter = signal(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private routeObserver: RouteObserverService,
    private gtmService: GoogleTagManagerService,
    private applicationRef: ApplicationRef,
    private configService: ConfigService,
    private translate: TranslocoService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setLanguage();

    this.routeObserver.currentRoute$.subscribe(({ url, data }) => {
      this.hideFooter.set(!!data.hideFooter);

      this.gtmService.pushTag({
        'event': 'Pageview',
        'pageUrl': url
      });
    });

    if (this.configService.isBrowser && !!this.configService.config.externalScripts) {
      this.applicationRef.isStable.pipe(
        first((isStable) => isStable)
      ).subscribe(() => {
        console.debug('APPLICATION STABLE !!!');
        this.addScriptsGoogleAnalytics();
        this.addScriptRecaptcha();
      });
    }
  }

  private setLanguage(): void {
    // eslint-disable-next-line @stylistic/max-len, no-useless-escape
    const botPattern = '(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
    const re = new RegExp(botPattern, 'i');

    if ((typeof navigator === 'object') && re.test(navigator.userAgent)) { // if a bot
      this.translate.setActiveLang('fr');
    } else {
      // Add english when trads ready
      // const browserLang = translate.getBrowserLang();
      // translate.setActiveLang(browserLang?.match(/fr|en/) ? browserLang : 'fr');
    }
  }

  private addScriptsGoogleAnalytics(): void {
    // Global site tag (gtag.js) - Google Analytics
    const script = this.renderer.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.configService.config.gtm}`;
    script.async = true;
    this.renderer.appendChild(this.document.body, script);

    const gtagScript = this.renderer.createElement('script');
    gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${this.configService.config.ga}');
    `;

    this.renderer.appendChild(this.document.body, gtagScript);
  }

  private addScriptRecaptcha(): void {
    const script = this.renderer.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.configService.config.recaptcha}`;
    this.renderer.appendChild(this.document.body, script);

    const scriptClick = this.renderer.createElement('script');
    script.innerHTML = `
			function onClick(e) {
				e.preventDefault()
				grecaptcha.ready(function () {
					grecaptcha.execute(${this.configService.config.recaptcha}, { action: "validate_captcha" }).then(function (token) {
						document.getElementById("g-recaptcha-response").value = token
					})
				})
			}
    `;

    this.renderer.appendChild(this.document.body, scriptClick);
  }
}
