import { ChangeDetectionStrategy, ChangeDetectorRef, Component, output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { AuthService } from '@/services/auth.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ForgotComponent {
  readonly closeModal = output();
  readonly back = output();

  public forgotForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  public submitted = false;
  public loading = false;
  public done = false;

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {}

  get email() {
    return this.forgotForm.get('email') as FormControl;
  }

  onClose(): void {
    this.closeModal.emit();
  }

  getBack(): void {
    this.back.emit();
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.forgotForm.valid) {
      this.loading = true;

      const { email } = this.forgotForm.value;

      this.authService.resetPassword(email!).subscribe({
        next: () => {
          this.done = true;
          this.loading = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          this.loading = false;
          console.error('ForgotComponent', error);
          this.cd.markForCheck();
        }
      });
    }
  }
}
