import { ChangeDetectionStrategy, Component, forwardRef, input, output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormTypeFilters, FilterTypeAnnonce, FilterTypeVendeur } from '@/models';

@Component({
  selector: 'app-type-filter',
  templateUrl: './type-filter.component.html',
  styleUrls: ['./type-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TypeFilterComponent),
    multi: true
  }],
  standalone: false
})
export class TypeFilterComponent implements ControlValueAccessor {
  readonly totalItems = input(0);

  readonly closed = output();
  readonly validate = output();
  readonly reinit = output<FormTypeFilters>();

  public switchBien: FilterTypeVendeur = 'particulier';
  public switchType: FilterTypeAnnonce = 'vente';
  public selected: boolean[][] = [[], []];
  public typeBien: string[] = [];

  public TYPE_BIENS: Record<FilterTypeVendeur, { key: string, value: string }[]> = {
    particulier: [
      { key: 'house', value: 'Maison' },
      { key: 'apartment', value: 'Appartement' },
      { key: 'duplex', value: 'Duplex' },
      { key: 'land', value: 'Terrain' },
      { key: 'holiday_property', value: 'Bien de Vacances' },
      { key: 'business', value: 'Commerce' },
      { key: 'building', value: 'Immeuble' },
      { key: 'realty', value: 'Propriété' },
      { key: 'farm', value: 'Ferme' },
      { key: 'box_garage', value: 'Parking-Box' }
    ],
    professionnel: [
      { key: 'commercial_premises', value: 'Local commercial' },
      { key: 'commercial_property', value: 'Fonds de commerce' },
      { key: 'offices', value: 'Bureaux' },
      { key: 'warehouses', value: 'Entrepôts' },
      { key: 'land', value: 'Terrains' },
      { key: 'assignment_of_lease', value: 'Cession de bail' },
      { key: 'walls', value: 'Murs' }
    ]
  } as const;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  handleSwitchType(type: FilterTypeAnnonce): void {
    this.switchType = type;
    this.update();
  }

  handleSwitchBien(mode: FilterTypeVendeur): void {
    this.switchBien = mode;
  }

  handleChange(mode: number, index: number, value: boolean): void {
    this.selected[mode][index] = value;
    this.update();
  }

  update(): void {
    this.formToValues();

    this.onChange({
      type_annonce: this.switchType,
      typeBien: this.typeBien
    });

    this.onTouched();
  }

  handleReset(): void {
    this.reinit.emit({ type_annonce: undefined, typeBien: [] });
  }

  handleSubmit(): void {
    this.validate.emit();
  }

  handleClose(): void {
    this.closed.emit();
  }

  writeValue(value: FormTypeFilters): void {
    this.switchType = value.type_annonce ?? 'vente';
    this.typeBien = value.typeBien ?? [];
    this.valuesToForm();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private valuesToForm(): void {
    const keys = Object.keys(this.TYPE_BIENS) as FilterTypeVendeur[];
    this.selected = keys.map((type) => (
      this.TYPE_BIENS[type].map((entry) => this.typeBien.includes(entry.value.toLocaleLowerCase()))
    ));
  }

  private formToValues(): void {
    const keys = Object.keys(this.TYPE_BIENS) as FilterTypeVendeur[];
    this.typeBien = keys.map((type, i) => (
      this.selected[i].map((sel, j) => sel ? this.TYPE_BIENS[type][j].value : undefined).filter((s) => !!s)
    )).flat() as string[];
  }
}
