import { BrowserModule, Meta, provideClientHydration, withEventReplay, withIncrementalHydration } from '@angular/platform-browser';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, IMAGE_CONFIG, registerLocaleData } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { NgModule, LOCALE_ID, provideAppInitializer } from '@angular/core';

import localeFr from '@angular/common/locales/fr';

import { httpErrorInterceptor, httpErrorAgenceInterceptor, httpAuthInterceptor } from '@/interceptors';
import { initAppConfig, initGoogleTagManager } from '@/utilities/app-initializers';
import { TranslocoRootModule } from '@/modules/translate/transloco-root.module';
import { CustomRouteReuseStategy } from '@/utilities/routes-reuse-strategy';
import { SharedModule } from '@/modules/shared/shared.module';
import { ToastModule } from '@/modules/toast/toast.module';
import { CoreModule } from '@/modules/core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    GoogleTagManagerModule.forRoot(),
    TranslocoRootModule,
    AppRoutingModule,
    ToastModule,
    CoreModule,
    SharedModule
  ],
  providers: [
    GoogleTagManagerService,
    Meta,
    provideHttpClient(
      withFetch(),
      withInterceptors([
        httpAuthInterceptor,
        httpErrorInterceptor,
        httpErrorAgenceInterceptor
      ])
    ),
    provideClientHydration(
      withIncrementalHydration(),
      withEventReplay()
    ),
    provideAppInitializer(initAppConfig),
    provideAppInitializer(initGoogleTagManager),
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      }
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStategy
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
