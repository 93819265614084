import { lastValueFrom, Subscription } from 'rxjs';

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy,
  SimpleChanges, TemplateRef, input, output
} from '@angular/core';

import {
  CurrentRoute, BurgerMenuItem, Actualite, Agence, ConnectedUser, AgenceGroupe, SearchMode, HeaderModalType, HeaderLink
} from '@/models';

import { BlogImmobilierService } from '@/services/blog.service';
import { MainRoutes, FavorisRoute } from '@/constants';

@Component({
  selector: 'app-header-agence',
  templateUrl: './header-agence.component.html',
  styleUrl: './header-agence.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class HeaderAgenceComponent implements OnDestroy, OnChanges {
  @Input() connectedUser?: ConnectedUser;
  @Input() currentRoute!: CurrentRoute;
  @Input() agence?: Agence;
  @Input() agenceGroupe?: AgenceGroupe;
  readonly headerShadow = input(true);
  readonly searchMode = input<SearchMode>();
  readonly queryParams = input<Record<string, number>>({});
  readonly filtersTemplate = input<TemplateRef<any>>();

  readonly openModal = output<HeaderModalType>();

  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;
  public FavorisRoute = FavorisRoute;

  public linkActive = 'underline text-blue1';
  public burgerItems: BurgerMenuItem[][] = [];
  public actualites?: Actualite[];
  public agenceLinks!: HeaderLink[];
  public isMobileSearchOpen = false;
  public agenceHome?: string;

  constructor(
    private blogService: BlogImmobilierService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {

    if (changes['agence'] && (changes['agence'].previousValue !== changes['agence'].currentValue)) {
      if (this.agence) {
        const response = await lastValueFrom(this.blogService.getActualitesAgence(this.agence.era_id));
        this.actualites = response.data;
      } else {
        this.actualites = undefined;
      }
    }

    this.burgerItems = this.buildBurgerItems();
    this.agenceLinks = this.buildAgenceLinks();

    this.cd.markForCheck();
  }

  handleLogin(): void {
    this.openModal.emit('login');
  }

  handleOpenAlert(): void {
    this.openModal.emit('alert');
  }

  toggleSearch(): void {
    this.isMobileSearchOpen = !this.isMobileSearchOpen;
  }

  closeSearch(): void {
    this.isMobileSearchOpen = false;
  }

  private buildBurgerItems(): BurgerMenuItem[][] {
    const prefix =
      this.agenceGroupe ? `${MainRoutes.Groupes}/${this.agenceGroupe.slug}` :
        this.agence ? `/${this.agence.slug}` : '';

    const queryParams: Record<string, any> =
      this.agenceGroupe ? { groupe_id: this.agenceGroupe.id } :
        this.agence ? { agence_id: this.agence.id } : {};

    const features = (this.agenceGroupe ?? this.agence)?.feature_display ?? {};
    const items: BurgerMenuItem[][] = [];

    items[0] = [
      { link: MainRoutes.Acheter, text: 'BUY', queryParams },
      { link: MainRoutes.Louer, text: 'RENT', queryParams },
      { link: MainRoutes.Estimation, text: 'SELL', queryParams }
    ];

    if (this.agence?.annonces_vendus?.length && (this.agence?.feature_display?.biens_vendus)) {
      items[0].push({ link: `${prefix}/vendu`, text: 'SOLD' });
    }

    items[1] = [];

    if (this.actualites?.length) {
      items[1].push({ link: `${prefix}${MainRoutes.Blog}`, text: 'BLOG' });
    }
    if (features?.syndic_copropriete) {
      items[1].push({ link: `${prefix}${MainRoutes.Syndic}`, text: 'SYNDIC' });
    }
    if (features?.gestion_locative) {
      items[1].push({ link: `${prefix}${MainRoutes.GestionLocative}`, text: 'MANAGEMENT' });
    }
    if (features?.entreprise_commerce) {
      items[1].push({ link: `${prefix}${MainRoutes.LocauxCommerciaux}`, text: 'COMMERCIALS' });
    }

    items[1].push({ link: MainRoutes.Effectifs, text: 'FINDTEAM' });
    items[1].push({ link: `${prefix}${MainRoutes.OffreEmploi}`, text: 'RECRUIT' });
    items[1].push({ href: 'https://www.franchise-era-immobilier.com', text: 'FRANCHISE' });

    return items;
  }

  private buildAgenceLinks(): HeaderLink[] {
    const links: HeaderLink[] = [{
      label: 'BUY',
      link: MainRoutes.Acheter,
      queryParams: this.queryParams()
    }, {
      label: 'RENT',
      link: MainRoutes.Louer,
      queryParams: this.queryParams()
    }];

    if (this.agence?.statut !== 2) {
      links.push({
        label: 'SELL',
        link: MainRoutes.Estimation,
        queryParams: this.queryParams()
      });
    }

    if (this.agenceGroupe) {
      const { slug, id, effectifs, emplois } = this.agenceGroupe;
      const baseLink = `${MainRoutes.Groupes}/${slug}-${id}`;

      this.agenceHome = `${MainRoutes.Groupes}/${slug}`;

      if (effectifs?.length > 0) {
        links.push({ label: 'TEAM', link: baseLink + '/equipes' });
      }
      if (emplois?.length > 0) {
        links.push({ label: 'RECRUIT', link: baseLink + '/emploi-immobilier' });
      }

    } else if (this.agence) {
      const { slug, effectifs, emplois, annonces_vendus, feature_display } = this.agence;
      const baseLink = `/${slug}`;

      this.agenceHome = `/${slug}`;

      if (effectifs?.length > 0) {
        links.push({ label: 'TEAM', link: baseLink + '/equipes' });
      }
      if (emplois?.length > 0) {
        links.push({ label: 'RECRUIT', link: baseLink + '/emploi-immobilier' });
      }
      if (annonces_vendus?.length && feature_display?.biens_vendus) {
        links.push({ label: 'SOLD', link: baseLink + '/vendu' });
      }
    }

    return links;
  }
}
