<app-modal-container>
	<div class="modal-validate">
		<div class="w-full flex justify-between content-center">
			<div class="title">
				<h3 class="display-h3-28px">{{ 'registration in progress' | transloco }}</h3>
			</div>
			<p (click)="onClose()">
				<span class="icon-close"></span>
			</p>
		</div>

		<div class="text">
			<p>{{ 'allow to connect' | transloco }}</p>
		</div>

		<app-button text="to login" type="submit" size="large" (click)="onModalLogin()" />
	</div>
</app-modal-container>
