<app-filter-dropdown
	modalTitle="location"
	[filterSet]="searchLocations.length > 0"
	[totalItems]="totalItems()"
	contentTitle="your_search"
	[contentScroll]="false"
	(filterSubmit)="handleSubmit()"
	(filterReset)="handleReset()"
	(filterClose)="handleClose()"
>
	<span filterButton>
		@if (!searchLocations.length && !searchOnMapMove && !polygon) {
			<span>France</span>
		} @else if (searchLocations.length && polygon) {
			{{ searchLocations[0].nom.split(',').join('-') }}
		} @else if (!searchLocations.length && searchOnMapMove) {
			Zone de recherche
		} @else if (!searchLocations.length && polygon && mode() !== 'agences') {
			{{ 'search_by_draw' | transloco }}
		} @else if (searchLocations.length && !polygon) {
			{{ searchLocations[0].nom.split(',').join('-') }}
		}

		@if (searchLocations.length > 1) {
			+{{ searchLocations.length - 1 }}
		}

		@if (searchLocations.length > 0) {
			<span class="ml-10px">
				<img src="/assets/imgs/loupe.svg" alt="search" height="16.67px" width="16.67px" />
			</span>
		} @else {
			<span class="ml-10px">
				<span class="icon-search" style="font-size: 16.67px;"></span>
			</span>
		}
	</span>

	<div filterContent>

		<div class="items-center relative rounded-full flex">
			@if (mode() === 'agences') {
				<div class="geoloc-button br-100px" (click)="handleAskForGeoloc()">
					<img src="/assets/imgs/position.svg" />
				</div>
			}

			<div class="input-location">
				<input
					type="text"
					placeholder="{{ 'type_city_or_dept' | transloco }}..."
					style="background-color: var(--color-grey-super-light)"
					[ngClass]="mode() === 'agences' ? 'pl-56px' : 'pl-18px'"
					[formControl]="locationInputValue"
				/>

				<div class="input-icon">
					<img src="/assets/imgs/loupe.svg" alt="search" height="16.67px" width="16.67px" />
				</div>
			</div>

			@if (showDropdown()) {
				<div class="dropdown" (clickOutside)="closeAutoComplete()">
					@if (foundCities.length > 0) {
						<div class="cities">
							<p class="header-title">
								{{ 'cities' | transloco }}
							</p>
							@for (city of foundCities; track city.id) {
								<div #cities class="suggest" (click)="handleSelect($event, city)">
									<span class="font-sfText">
										{{ city.nom }} <app-text-round-separator />
									</span>
									<span class="font-sfText">
										({{ city.code }})
									</span>
								</div>
							}
						</div>
					}
					@if (foundDepartments.length > 0) {
						<div class="departments">
							<p class="header-title">
								{{ 'departments' | transloco }}
							</p>
							@for (dep of foundDepartments; track dep.id) {
								<div #departments class="suggest" (click)="handleSelect($event, dep)">
									<span class="font-sfText">
										{{ dep.nom }} <app-text-round-separator />
									</span>
									<span class="font-sfText">
										({{ dep.code }})
									</span>
								</div>
							}
						</div>
					}
				</div>
			}
		</div>

		<div class="modal-tags w-full mt-10px mb-10px^">
			@for (item of searchLocations; track item.nom) {
				<span class="tag display-text-16px">
					{{ item.nom.split(',').join('-') }} <span class="close icon-close" (click)="handleUnselect($event, item)"></span>
				</span>
			}
		</div>

		@if (showRadiusFilter) {
			<h4 class="display-text-16px font-bold mt-20px mb-0">
				{{ 'search_radius' | transloco }}
			</h4>
			<p class="display-text-14px">
				{{ 'around_cities' | transloco }}
			</p>
			<div class="slider-container">
				<app-slider
					max="50"
					step="5"
					[showLabel]="false"
					[ngModel]="distance"
					(ngModelChange)="sliderChange($event)"
				/>
				<p class="display-text-14px">
					{{ distance }} <span>km</span>
				</p>
			</div>
		}

	</div>
</app-filter-dropdown>