import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContentService } from '@/services/content.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { ngSkipHydration: 'true' },
  standalone: false
})
export class NotFoundComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public content_404?: string;

  constructor(
    private contentService: ContentService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.contentService.getContent().subscribe(((data) => {
        this.content_404 = data.texte_404;
        this.cd.markForCheck();
      }))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
