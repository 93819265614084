import { TranslocoService } from '@jsverse/transloco';
import { inject } from '@angular/core';

import { translocoConfig } from './transloco-config';

export const translocoPreLoad = () => {
  const transloco = inject(TranslocoService);

  const defaultLang = translocoConfig.config.defaultLang ?? 'fr';
  transloco.setActiveLang(defaultLang);
  return transloco.load(defaultLang);
};
