import { Component, ViewEncapsulation, output } from '@angular/core';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class FacebookComponent {
  readonly connect = output<'facebook'>();

  submitLogin(): void {
    this.connect.emit('facebook');
  }
}
