import { Component, ViewEncapsulation, output } from '@angular/core';

@Component({
  selector: 'app-google',
  templateUrl: './google.component.html',
  styleUrls: ['./google.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class GoogleComponent {
  readonly connect = output<'google'>();

  submitLogin(): void {
    this.connect.emit('google');
  }
}
