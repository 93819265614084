import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnChanges,
  OnDestroy, OnInit, SimpleChanges, TemplateRef, input, output
} from '@angular/core';

import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CurrentRoute, Actualite, BurgerMenuItem, ConnectedUser, HeaderModalType, SearchMode, Annonce, HeaderLink } from '@/models';
import { ConfigService } from '@/services/config.service';
import { HeaderService } from '@/services/header.service';
import { MainRoutes, FavorisRoute } from '@/constants';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrl: './header-main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class HeaderMainComponent implements OnInit, OnDestroy, OnChanges {
  readonly filtersTemplate = input<TemplateRef<any> | null>();
  @Input() connectedUser?: ConnectedUser;
  @Input() currentRoute!: CurrentRoute;
  readonly actualites = input<Actualite[]>();
  readonly headerShadow = input(true);
  readonly searchMode = input<SearchMode>();

  readonly openModal = output<HeaderModalType>();

  private subscriptions = new Subscription();
  public FavorisRoute = FavorisRoute;
  public MainRoutes = MainRoutes;

  public showSearchBar = false;
  public url_front: string;
  public linkActive = 'underline text-blue1';
  public isMobileSearchOpen = false;
  public openSocialShare = false;
  public zoomLogo = false;
  public bigLogo = false;
  public emptyHeart = true;
  public annonce?: Annonce;

  public burgerItems: BurgerMenuItem[][] = [[
    { link: MainRoutes.Acheter, text: 'BUY' },
    { link: MainRoutes.Louer, text: 'RENT' },
    { link: MainRoutes.Estimation, text: 'SELL' }
  ], [
    { link: MainRoutes.Blog, text: 'BLOG' },
    { link: MainRoutes.Syndic, text: 'SYNDIC' },
    { link: MainRoutes.GestionLocative, text: 'MANAGEMENT' },
    { link: MainRoutes.LocauxCommerciaux, text: 'COMMERCIALS' },
    { link: MainRoutes.Recrutement, text: 'RECRUIT' },
    { link: MainRoutes.Effectifs, text: 'FINDTEAM' },
    { href: 'https://www.franchise-era-immobilier.com', text: 'FRANCHISE' }
  ]];

  public links: HeaderLink[] = [{
    label: 'BUY',
    link: MainRoutes.Acheter
  }, {
    label: 'RENT',
    link: MainRoutes.Louer
  }, {
    label: 'SELL',
    link: MainRoutes.Estimation
  }, {
    label: 'RECRUIT',
    link: MainRoutes.Recrutement
  }];

  constructor(
    private configService: ConfigService,
    private headerService: HeaderService,
    private cd: ChangeDetectorRef,
    private location: Location,
    private router: Router
  ) {
    this.url_front = this.configService.config.url_front;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.headerService.headerState$.subscribe(({ showSearch, currentAnnonce }) => {
        this.emptyHeart = !currentAnnonce?.is_favorite;
        this.showSearchBar = showSearch;
        this.annonce = currentAnnonce;
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentRoute']?.currentValue) {
      this.zoomLogo = !!this.currentRoute.data.zoomLogo;
      this.bigLogo = this.zoomLogo;
      this.cd.markForCheck();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    if (this.zoomLogo) {
      const doc = event.target;

      if (doc) {
        const verticalOffset = event.currentTarget?.scrollY || doc.documentElement?.scrollTop || doc.body?.scrollTop || 0;
        if (verticalOffset > 10) {
          this.bigLogo = false;
          return;
        }
      }

      this.bigLogo = true;
    }
  }

  handleLogin(): void {
    this.openModal.emit('login');
  }

  handleOpenAlert(): void {
    this.openModal.emit('alert');
  }

  goBack(): void {
    if (this.configService.isBrowser) {
      if (window.history.length > 2) {
        this.location.back();
      } else {
        this.router.navigate([MainRoutes.Acheter]);
      }
    }
  }

  closeSearch(): void {
    this.isMobileSearchOpen = false;
  }

  toggleSearch(e: Event): void {
    this.isMobileSearchOpen = !this.isMobileSearchOpen;
    e.stopPropagation();
  }
}
